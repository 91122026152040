<template>
    <div class="questionnaire-select">
      <LoadingAllScreen :is-loading.sync="loading" :scroll-lock="false"/>
      <div >
        <div class="text-center mt-2">
          <h4>Quiz score a number of times</h4>
        </div>
        <div class="questions">
          <span
            v-for="index in countAnswers"
            :key="index"
            class="d-flex"
            cols="6"
            sm="1"
          >
            <v-select
              :items="items"
              :label="'Question ' + index"
              v-model="questionResult[index]"
              :key="index"
              return-object
              @change="answers => selectResult(answers, index, item.id)"
            ></v-select>
          </span>
          <span
            class="d-flex"
            cols="6"
            sm="1"
          >
            <v-select
              :items="NumberOfTimes"
              :value="numberOfTimes"
              label="Number Of Times"
              return-object
              @change="answers => selectNumberOfTimes(answers, item.id)"
            ></v-select>
          </span>
          <v-btn
            class="button-send"
            elevation="7"
            :disabled="disabled || !completeU1"
            @click="sendQuestionnaireResult"
          >Submit</v-btn>
          <v-btn
            class="button-send"
            elevation="7"
            @click="resetAllQuestion"
          >Reset All</v-btn>
        </div>
      </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'QuestionnareSelect',
  props: {
    expanded: {
      type: Array,
    },
    item: {
      type: Object,
    },
    completeU1: {
      type: Boolean,
    },
  },
  data: () => ({
    questionResult: {},
    disableSubmit1: true,
    disableSubmit2: true,
    countAnswers: 10,
    items: ['1', '2', '3', '4', '5', '6'],
    numberOfTimes: 1,
    NumberOfTimes: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getAnswersAndUserId: 'admin/getAnswersAndUserId',
    }),
    disabled() {
      if (!this.disableSubmit2) {
        if (!this.disableSubmit1) return false;
      }
      return true;
    },
  },
  watch: {
    expanded() {
      this.$forceUpdate();
      this.disableSubmit2 = true;
      this.disableSubmit1 = true;
      this.questionResult = {};
      this.numberOfTimes = 1;
      if (this.getAnswersAndUserId[this.item.id]) {
        this.questionResult = this.getAnswersAndUserId[this.item.id];
        this.numberOfTimes = this.getAnswersAndUserId[this.item.id].numberOfTimes;
        if (Object.keys(this.questionResult).length === 12) {
          this.disableSubmit2 = false;
          this.disableSubmit1 = false;
        }
      }
    },
  },
  mounted() {
    if (this.getAnswersAndUserId[this.item.id]) {
      this.questionResult = this.getAnswersAndUserId[this.item.id];
      this.numberOfTimes = this.getAnswersAndUserId[this.item.id].numberOfTimes;
      if (Object.keys(this.questionResult).length === 12) {
        this.disableSubmit2 = false;
        this.disableSubmit1 = false;
      }
    }
  },
  methods: {
    resetAllQuestion() {
      this.questionResult = {};
      this.numberOfTimes = 1;
      this.$store.dispatch('admin/removeAnswersAndUserId', this.item.id).then(() => {
        this.disableSubmit2 = true;
        this.disableSubmit1 = true;
      });
    },
    async sendQuestionnaireResult() {
      this.loading = true;
      delete this.questionResult.userId;
      delete this.questionResult.numberOfTimes;
      try {
        await this.$api.admin.addQuestionnaireResult(process.env.QUESTIONNAIRE_ID, this.item.id,
          this.questionResult, +this.numberOfTimes);
        this.$emit('update-data');
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    selectNumberOfTimes(count, userId) {
      if (this.currentUser === userId) {
        this.numberOfTimes = count;
        this.disableSubmit1 = false;
        if (this.getAnswersAndUserId[this.item.id].numberOfTimes) {
          this.disableSubmit1 = false;
        }
      } else {
        this.numberOfTimes = 1;
        this.numberOfTimes = count;
        this.disableSubmit1 = false;
        this.currentUser = userId;
        if (this.getAnswersAndUserId[this.item.id].numberOfTimes) {
          this.disableSubmit1 = false;
        }
      }
      const data = {
        userId,
        numberOfTimes: this.numberOfTimes,
      };
      this.$store.dispatch('admin/setAnswersAndUserId', data).then(() => {
        if (Object.keys(this.getAnswersAndUserId[this.item.id]).length === 12) {
          this.disableSubmit2 = false;
          this.disableSubmit1 = false;
        }
      });
    },

    selectResult(answer, question, userId) {
      if (this.currentUser === userId) {
        this.questionResult[question] = answer;
        if (Object.keys(this.questionResult).length === 12) {
          this.disableSubmit2 = false;
        }
      } else if (this.getAnswersAndUserId[this.item.id]) {
        this.questionResult = this.getAnswersAndUserId[this.item.id];
      } else {
        this.questionResult = {};
        this.questionResult[question] = answer;
        this.currentUser = userId;
      }
      const data = {
        userId,
        [question]: answer,
      };
      this.$store.dispatch('admin/setAnswersAndUserId', data).then(() => {
        if (Object.keys(this.getAnswersAndUserId[this.item.id]).length === 12) {
          this.disableSubmit2 = false;
          this.disableSubmit1 = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .questionnaire-select {
    width: 50%;
    height: 100%;
    float: right;
    padding-right: 80px;
  }
  .questions {
    float: right;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    > span {
      padding-right: 20px;
      width: 276px;
    }
  }
  .button-send {
    margin: 13px;
  }
</style>
